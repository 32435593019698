import { Component, effect, ElementRef, input, output, signal, ViewChild } from '@angular/core';
import { BOELS_EMPTY_VALUE_PLACEHOLDER } from '@boels-experience/core';
import { MatIcon } from '@angular/material/icon';
import { ButtonComponent } from '@boels-experience/forms';
import { ListItemComponent } from '@boels-experience/layout';
import {
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
} from '@angular/forms';
import { BoelsInputCheckboxComponent } from '@boels-shared/components/input-checkbox/boels-input-checkbox.component';

@Component({
  selector: 'bds-list-item-input',
  standalone: true,
  imports: [ButtonComponent, MatIcon, BoelsInputCheckboxComponent, FormsModule, ReactiveFormsModule],
  templateUrl: './list-item-input.component.html',
  styleUrl: './list-item-input.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemInputComponent extends ListItemComponent {
  public value = input.required<string>();
  public maxlength = input<number>();
  public placeholder = input<string>(BOELS_EMPTY_VALUE_PLACEHOLDER);
  public validators = input<Array<ValidatorFn | AsyncValidatorFn>>([]);

  public onEdit = output();
  public onCancel = output();
  public onSubmit = output<string>();

  @ViewChild('inputElement', { static: false })
  private readonly inputElement!: ElementRef;

  protected override get instanceHostClasses(): string {
    return `-input bds-list-item--${this.editActive() ? 'active' : 'inactive'} `;
  }

  public editActive = signal(false);

  public form = new FormGroup({
    value: new FormControl(null, []),
  });

  private readonly updateValidators = effect(() => {
    this.form.controls['value'].setValidators([...this.validators()]);
    this.form.controls['value'].updateValueAndValidity();
  });

  public edit(): void {
    this.form.reset();
    this.form.controls['value'].setValue(this.value());

    this.onEdit.emit();

    this.editActive.set(true);
    this.focusInput();
  }

  private focusInput(): void {
    setTimeout(() => {
      if (this.inputElement?.nativeElement) {
        this.inputElement.nativeElement.focus();
      }
    });
  }

  public cancel(): void {
    this.onCancel.emit();
    this.editActive.set(false);
  }

  public submit(): void {
    this.onSubmit.emit(this.form.controls['value'].value);
    this.editActive.set(false);
  }

  public onKeyup(event: KeyboardEvent): void {
    // Check if the pressed key is "Enter" and the form is valid
    if (event.key === 'Enter' && this.form.valid) {
      this.submit();
    } else if (event.key === 'Escape') {
      this.cancel();
    }
  }
}
