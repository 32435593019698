import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  HostBinding,
  inject,
  input,
  TemplateRef,
} from '@angular/core';
import { BdsListService } from '../list/list.component';
import { BdsLabelSuffixComponent } from '@boels-experience/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[bds-list-item]',
  standalone: true,
  templateUrl: './list-item.component.html',
  styleUrl: './list-item.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemComponent {
  private readonly listService = inject(BdsListService);

  public label = input.required<string>();
  public role = input<string>('listitem');

  @ContentChild(BdsLabelSuffixComponent)
  public labelSuffixComponent: TemplateRef<any> | undefined;

  // Bind the classes to the host element, accessing the signals directly
  @HostBinding('class')
  private get hostClassBinding(): string {
    return (
      `bds-list-item bds-list-item--content-density-${this.listService.contentDensity()} ` + this.instanceHostClasses
    );
  }

  @HostBinding('attr.aria-label')
  private get ariaLabel(): string {
    return this.label();
  }

  @HostBinding('attr.role')
  private get hostRole(): string {
    return this.role();
  }

  protected get instanceHostClasses(): string {
    return '';
  }
}
