@if (heading()) {
  <h3>{{ heading() }}</h3>
}
@if (headerTemplate) {
  <div class="bds-card__header">
    <ng-content select="[bds-header]"></ng-content>
  </div>
}
<ng-content></ng-content>
@if (footerTemplate) {
  <div class="bds-card__footer">
    <ng-content select="[bds-footer]"></ng-content>
  </div>
}
