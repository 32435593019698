<span class="bds-list-item__label -input">
  {{ label() }}
  @if (labelSuffixComponent) {
    <span class="bds-list-item__label-suffix -input">
      <ng-content select="[bds-label-suffix]"></ng-content>
    </span>
  }
</span>
<div class="bds-list-item__content -input">
  @if (editActive()) {
    <span class="bds-list-item-input__value -edit" [formGroup]="form">
      <input #inputElement [formControlName]="'value'" (keyup)="onKeyup($event)" [attr.maxlength]="maxlength()" />
    </span>
  } @else {
    @if (value()) {
      <span class="bds-list-item__value -input">{{ value() }}</span>
    } @else {
      <span class="bds-list-item__value -input -placeholder">{{ placeholder() }}</span>
    }
  }
</div>
<div class="bds-list-item__buttons -input">
  @if (editActive()) {
    <button bds-button icon (click)="submit()" [disabled]="form.invalid">
      <mat-icon svgIcon="checkmark-solid"></mat-icon>
    </button>
    <button bds-button icon (click)="cancel()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  } @else {
    <button bds-button icon (click)="edit()">
      <mat-icon svgIcon="pen" style="transform: scale(75%)"></mat-icon>
    </button>
  }
</div>
