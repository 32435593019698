import { Component, ContentChild, input, TemplateRef } from '@angular/core';
import { BdsFooterComponent, BdsHeaderComponent } from '@boels-experience/core';

@Component({
  selector: 'bds-card',
  standalone: true,
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss',
})
export class CardComponent {
  public heading = input<string>();

  @ContentChild(BdsHeaderComponent)
  public headerTemplate: TemplateRef<any> | undefined;

  @ContentChild(BdsFooterComponent)
  public footerTemplate: TemplateRef<any> | undefined;
}
