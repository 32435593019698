import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  inject,
  Injectable,
  input,
  signal,
  Signal,
} from '@angular/core';

export const CONTENT_DENSITY = {
  REGULAR: 'regular',
  DENSE: 'dense',
} as const;

// List of CONTENT_DENSITY; 'regular' | 'dense'
export type ContentDensity = (typeof CONTENT_DENSITY)[keyof typeof CONTENT_DENSITY];

@Injectable({ providedIn: 'root' })
export class BdsListService {
  // The initial signal assignment was added for display in Storybook
  public contentDensity: Signal<ContentDensity> = signal('regular');
}

@Component({
  selector: 'bds-list',
  standalone: true,
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss',
  providers: [{ provide: BdsListService }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent {
  private readonly listService = inject(BdsListService);

  public contentDensity = input<ContentDensity>('regular');

  constructor() {
    // Set instance-specific data
    this.listService.contentDensity = computed(() => this.contentDensity());
  }

  @HostBinding('attr.role')
  private get hostRole(): string {
    return 'list';
  }
}
